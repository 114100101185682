$base-blue: #011936;
$base-white: #f5fbef;
$base-light-green: #CE71CB;
$base-dark-green: #748b75;
$base-glow-green: #00cccc;
@import url("https://fonts.googleapis.com/css2?family=Audiowide&display=swap");
@font-face {
    font-family: "Commodore 64 Rounded";
    font-style: normal;
    font-weight: normal;
    src: local("Commodore 64 Rounded"), url("./assets/fonts/CommodoreRounded.woff") format("woff");
}

@keyframes flicker {
    0%,
    19.999%,
    22%,
    62.999%,
    64%,
    64.999%,
    70%,
    100% {
        //text-shadow: none;
        opacity: 0.99;
    }
    20%,
    21.999%,
    63%,
    63.999%,
    65%,
    69.999% {
        opacity: 0.4;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

html {
    scroll-behavior: smooth;
}

body {
    // background-color: $base-blue;
}

input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.App-header {
    background-color: transparent;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#loader-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    // padding: 100px 0;
    background-color: $base-blue;
    overflow: hidden;
    @keyframes slide-up {
        0% {
            top: 100vh;
        }
        100% {
            top: 0;
        }
    }
    #loader {
        // position: absolute;
        // top: 25vh;
        position: absolute;
        left: 1vw;
        bottom: 1vw;
        z-index: 5;
        width: 17vw;
        min-width: 100px;
    }
    #platform {
        background: url("./assets/images/building/platform-guy.png");
        z-index: 1;
        // animation: 2s linear 2 flicker;
        animation: 1s linear 3 flicker;
    }
    #building {
        position: absolute;
        background: url("./assets/images/building/front-building.png");
        z-index: 2;
        animation: 2s linear 2 flicker;
    }
    #city {
        background: url("./assets/images/building/city.png");
        z-index: 0;
        // animation: 3s linear slide-up;
    }
    #platform,
    #building,
    #city {
        top: 0;
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        justify-content: center;
        align-items: center;
    }
    p {
        display: none;
        letter-spacing: 0.2rem;
        color: $base-glow-green;
        font-family: "Commodore 64 Rounded";
        font-size: 4rem;
    }
}

.switch-logo {
    @keyframes entry-logo {
        from {
            margin-top: -500px;
        }
        to {
            margin-top: 0px;
        }
    }
    height: 18vh;
    transition: 1s all ease;
    animation: 0.9s linear entry-logo;
}

.bar h6,
.connect-button {
    font-family: "Commodore 64 Rounded";
}

.connect-button {
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 3px 20px red;
}

@keyframes barAppear {
    0% {
        top: -50px;
    }
    100% {
        top: 0;
    }
}

.bar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 2600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.7); //rgba(255, 255, 255, 0);
    height: 50px;
    padding: 0px 40px;
    transition: 0.4s ease all;
    animation: 1s ease barAppear;
    align-items: center;
    z-index: 9999999;
    @media (max-width: 1150px) {
        padding-left: 5vw;
    }
    .header-logo {
        display: block;
        margin-top: 0px;
        transition: 0.4s ease all;
        height: 90px;
    }
    & h6 {
        font-size: calc(8px + 1vmin);
    }
    #text-logo-container {
        span {
            letter-spacing: 0.2rem;
            color: $base-glow-green;
            font-family: "Commodore 64 Rounded";
            font-size: 1.5rem;
            @media (max-width: 1150px) {
                font-size: 1rem;
                letter-spacing: 0.1rem;
            }
        }
    }
    #menu-nav {
        ul {
            color: $base-glow-green;
            font-family: "Commodore 64 Rounded";
            letter-spacing: 0.2rem;
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0px 10px;
            font-size: 1.2rem;
            .connect-wallet-button {
                background-color: transparent;
                color: $base-glow-green;
                border: 1px solid $base-glow-green;
                margin-left: 0.5vw;
                margin-right: 0.5vw;
                font-size: 1rem;
                padding: 10px 10px;
                font-family: "Commodore 64 Rounded";
                // -webkit-clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
                // clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 20px 100%, 0 calc(100% - 20px));
                &.flicker {
                    animation: flicker 2.4s linear infinite;
                }
                &.active {
                    color: $base-light-green;
                }
                &:hover {
                    color: $base-white;
                    border: 1px solid $base-white;
                    cursor: pointer;
                }
            }
            li {
                list-style: none;
                padding: 10px 10px;
                margin: 0px 2px;
                &:hover {
                    color: $base-white;
                }
                &.play-button {
                    cursor: pointer;
                    &:hover {
                        color: $base-white;
                    }
                }
            }
        }
        @media (max-width: 1150px) {
            display: none;
        }
    }
    #mobile-nav {
        display: none;
        @media (max-width: 1150px) {
            display: flex;
        }
    }
    &.scrolled {
        position: fixed !important;
        left: 0;
        right: 0;
        top: 0;
        background-color: $base-blue !important;
        // height: 80px;
        width: 100%;
        // z-index: 9;
        #menu-nav {
            ul {
                color: $base-white;
            }
            li {
                &.play-button {
                    border: 1px solid $base-blue;
                    &:hover {
                        background-color: $base-blue;
                        color: $base-white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.logo-chain {
    height: 30px;
    vertical-align: middle;
    padding-left: 20px;
}

.city-background {
    background: url("./assets/images/city-bg.gif");
    background-size: cover !important;
    background-position: center !important;
}

h4 {
    font-size: 2rem !important;
    font-family: "Commodore 64 Rounded";
    color: $base-light-green;
}

h6 {
    font-size: 3rem;
    font-family: "Commodore 64 Rounded";
    color: $base-glow-green;
}

.scroller {
    position: absolute;
    top: 0;
    height: 1px;
    width: 1px;
}

#animation-container {
    position: relative;
    background-color: rgba(0, 0, 0, 0.2) !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 2500px;
    height: 96vh;
    font-variant: small-caps;
    // z-index: 1000;
    @media (max-width: 800px) {
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: center !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        height: 85vh !important;
    }
    h6 {
        margin: 4vh auto 10vh auto;
    }
    .up-panel {
        display: flex;
        flex-direction: column;
    }
    .down-panel {
        background: linear-gradient(217deg, transparent, $base-light-green 100%);
        border-radius: 10px;
    }
    .scroll-arrow-container {
        margin-bottom: 3vh;
        font-size: 120px;
    }
    #logo-n-social {
        display: flex;
        flex-direction: column;
        #social {
            margin-top: 1.5vh;
            display: flex;
            justify-content: space-evenly;
            .social-logo {
                width: 40px;
                height: 40px;
                background-size: cover;
                &.discord {
                    background-image: url("./assets/images/social/discord.png");
                }
                &.twitter {
                    background-image: url("./assets/images/social/twitter.png");
                }
                &.instagram {
                    background-image: url("./assets/images/social/instagram.png");
                }
            }
        }
    }
    #tag-n-mint {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
            background: rgba(0, 0, 0, 0.7);
            font-family: "Commodore 64 Rounded";
            position: relative;
            display: inline-block;
            padding: 15px 25px;
            color: $base-glow-green;
            text-transform: uppercase;
            letter-spacing: 4px;
            text-decoration: none;
            font-size: 2.1rem;
            overflow: hidden;
            transition: 0.2s;
            border-radius: 0.3rem;
        }
        a:hover {
            color: #255784;
            background: $base-glow-green;
            box-shadow: 0 0 10px $base-glow-green, 0 0 40px $base-glow-green, 0 0 80px $base-glow-green;
            transition-delay: 1s;
        }
        a span {
            position: absolute;
            display: block;
        }
        a span:nth-child(1) {
            top: 0;
            left: -100%;
            width: 100%;
            height: 5px;
            background: linear-gradient(90deg, transparent, $base-glow-green);
        }
        a:hover span:nth-child(1) {
            left: 100%;
            transition: 1s;
        }
        a span:nth-child(3) {
            bottom: 0;
            right: -100%;
            width: 100%;
            height: 5px;
            background: linear-gradient(270deg, transparent, $base-glow-green);
        }
        a:hover span:nth-child(3) {
            right: 100%;
            transition: 1s;
            transition-delay: 0.5s;
        }
        a span:nth-child(2) {
            top: -100%;
            right: 0;
            width: 5px;
            height: 100%;
            background: linear-gradient(180deg, transparent, $base-glow-green);
        }
        a:hover span:nth-child(2) {
            top: 100%;
            transition: 1s;
            transition-delay: 0.25s;
        }
        a span:nth-child(4) {
            bottom: -100%;
            left: 0;
            width: 5px;
            height: 100%;
            background: linear-gradient(360deg, transparent, $base-glow-green);
        }
        a:hover span:nth-child(4) {
            bottom: 100%;
            transition: 1s;
            transition-delay: 0.75s;
        }
        // #home-mint {
        //     text-align: center;
        //     width: 200px;
        //     background-color: $base-glow-green;
        //     font-family: "Commodore 64 Rounded";
        //     font-size: 2rem;
        //     color: $base-white;
        //     padding: 20px;
        //     margin-top: 3vh;
        //     margin-bottom: 3vh;
        // }
    }
}

#spacer {
    width: 100%;
    // height: 2vh;
    background: linear-gradient(to bottom, transparent, $base-blue);
}

#moz-rules,
#moz-rarity,
#moz-mint,
#moz-roadmap {
    position: relative;
    box-shadow: inset 0 0 8px 6px rgb(0 0 0 / 50%);
    width: 100%;
    max-width: 2500px;
    height: 20vh;
    overflow: hidden;
    #moz-rulesimg {
        // background: url("./assets/images/moz-rules.jpg");
        background-size: contain;
        background-repeat: repeat-y;
        width: 100%;
        height: 600%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #rules-title {
        background: url("./assets/images/pixel-rules.png");
        // filter: drop-shadow(0 0 10px $base-glow-green);
        background-size: contain;
        background-repeat: repeat-y;
        width: 100%;
        height: 600%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #about-title {
        background: url("./assets/images/pixel-rarity.png");
        // filter: drop-shadow(0 0 10px $base-glow-green);
        background-size: contain;
        background-repeat: repeat-y;
        width: 100%;
        height: 600%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #mint-title {
        background: url("./assets/images/pixel-mint.png");
        // filter: drop-shadow(0 0 10px $base-glow-green);
        background-size: contain;
        background-repeat: repeat-y;
        width: 100%;
        height: 600%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #roadmap-title {
        background: url("./assets/images/pixel-roadmap.png");
        // filter: drop-shadow(0 0 10px $base-glow-green);
        background-size: contain;
        background-repeat: repeat-y;
        width: 100%;
        height: 600%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

#moz-mint {
    box-shadow: none !important;
}

#game-rules {
    position: relative;
    width: 100%;
    max-width: 1000px;
    display: flex;
    padding: 100px 5vw 0 5vw;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // z-index: 8;
    transition: 1s all linear;
    &:hover {
        background-color: rgba(0, 0, 0, 0.9);
    }
    p {
        // line-height: 25px;
        position: relative;
        text-align: center;
        max-width: 1000px;
        font-family: 'Commodore 64 Rounded', sans-serif;
        text-transform: uppercase;
        margin-bottom: 5vh;
        text-align: left;
        border-right: 2px solid $base-glow-green;
        border-bottom: 2px solid $base-glow-green;
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: $base-glow-green;
        padding: 0 3vh 3vh 3vh;
        background: rgba(0, 0, 0, 0.85);
        span {
            color: $base-light-green;
        }
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 2px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 3px;
        }
        h4 {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-transform: uppercase;
            width: auto;
            cursor: pointer;
            text-align: right;
            padding-bottom: 2vh;
            margin-top: 2vh;
            line-height: 2rem;
            // margin-bottom: 0vh;
            // &:after {
            //     content: "";
            //     position: absolute;
            //     left: 0;
            //     bottom: 0;
            //     width: 60%;
            //     height: 2px;
            //     background-color: $base-glow-green;
            // }
        }
    }
    h6 {
        font-size: 5rem;
        margin: 0;
        margin-bottom: 3vh;
        @media (max-width: 800px) {
            font-size: 3rem;
        }
    }
}

#wanted-sample {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        max-width: 17vw;
        @media (max-width: 1000px) {
            max-width: 33vw;
        }
    }
    span {
        font-family: "Commodore 64 Rounded";
        font-size: 1.1rem;
        margin-top: 1vh;
        color: $base-white;
    }
}

#reward-stats {
    span {
        color: $base-blue;
    }
}

@keyframes slidein {
    from {
        top: 60px;
    }
    to {
        top: -3000px;
    }
}

#mint {
    position: relative;
    width: 100%;
    max-width: 1000px;
    min-height: 80vh;
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    padding: 100px 3vh;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // z-index: 8;
    transition: all 0.5s ease;
    &:hover {}
    h6 {
        font-size: 6rem;
        margin: 0;
        margin-bottom: 5vh;
        @media (max-width: 800px) {
            font-size: 3rem;
        }
    }
    .scroll-arrow-container {
        margin-top: 4vh;
        font-size: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        p {
            font-size: 1rem;
            font-family: "Commodore 64 Rounded";
            color: $base-white;
            margin-bottom: -10px;
        }
        svg {
            margin-top: 0;
        }
    }
    #mint-container {
        display: flex;
        position: relative;
        overflow: hidden;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        padding: 3vh;
        border-right: 2px solid $base-glow-green;
        border-bottom: 2px solid $base-glow-green;
        // background: rgba(0, 0, 0, 0.85);
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 2px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 3px;
        }
        @media (max-width: 800px) {
            flex-direction: column;
            align-items: center;
        }
        #sample-bastard-container {
            // background-image: url('./assets/images/item_mint.png');s
            padding: 20px;
            position: relative;
            .sample-bastard {
                max-width: 300px;
                border-left: 3px solid $base-light-green;
                border-top: 3px solid $base-light-green;
                @media (max-width: 800px) {
                    max-width: 200px !important;
                }
                margin-right: 3vw;
            }
        }
        #mint-actions {
            display: flex;
            flex-direction: column;
        }
        #mint-actions,
        #wallet-connect-action {
            display: flex;
            justify-content: space-between;
            input {
                width: 60px;
                font-family: "Commodore 64 Rounded";
                text-align: right;
                padding-right: 5px;
                font-size: 1.2rem;
            }
            p {
                font-family: "Commodore 64 Rounded";
                color: $base-white;
                font-size: 1.2rem;
                text-transform: uppercase;
                span {
                    color: $base-light-green;
                }
            }
            #whitelist-message {
                font-size: 1.4rem;
                color: $base-glow-green !important;
            }
            a {
                display: flex;
                margin-top: 5vh;
                text-transform: lowercase;
            }
            #mint-sub-actions {
                display: flex;
                justify-content: center;
            }
            .button {
                margin-left: 1vw;
                padding: 10px 15px;
                font-size: 1.2rem;
                border-radius: 5px;
                color: $base-glow-green;
                background-color: transparent;
                font-family: "Commodore 64 Rounded";
                border: 1px solid $base-glow-green;
                cursor: pointer;
                &:hover {
                    color: $base-white;
                    border-color: $base-white;
                }
            }
        }
    }
}

#mint-loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.99);
    z-index: 20;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h4 {
        span {
            font-size: 1.1rem;
        }
    }
    .mint-loader {
        position: relative;
        padding: 5vh;
        width: 600px;
        border-right: 2px solid $base-glow-green;
        border-bottom: 2px solid $base-glow-green;
        background: rgba(0, 10, 10, 0.95);
        border-top-left-radius: 25%;
        @media(max-width: 700px) {
            width: 100%;
        }
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 2px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 3px;
        }
        .mint-action-element {
            display: flex;
            flex-direction: row;
            align-items: center;
            img {
                width: 32px;
                height: 32px;
                margin-right: 30px;
            }
            p {
                font-family: "Commodore 64 Rounded";
                color: $base-white;
            }
        }
        .menu-bottom-button {
            cursor: pointer;
            position: relative !important;
            margin-top: 5vh !important;
            margin-bottom: 0 !important;
            color: $base-white;
        }
    }
}

.rule-details {
    background: rgba(0, 0, 0, 0.85);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    // height: 600px;
    max-width: 1000px;
    align-items: center;
    padding: 2vh;
    box-shadow: inset 0 0 8px 6px rgb(0 0 0 / 50%);
    p {
        text-align: center;
        max-width: 1000px;
        font-size: 1.3rem !important;
        font-family: "Commodore 64 Rounded";
        color: $base-white !important;
        margin-bottom: 5vh;
        text-align: justify;
        @media (max-width: 1150px) {
            text-align: left;
        }
    }
    #wanted-container {
        position: relative;
        padding: 4vh 0 0 0;
        color: $base-blue;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-right: 6px solid $base-glow-green;
        border-bottom: 6px solid $base-glow-green;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 4px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 5px;
        }
        section {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: 800px) {
                flex-direction: row;
                margin-bottom: 4vh;
            }
            img {
                width: 15vw;
                max-width: 250px;
                margin: 0 2vw;
                @media (max-width: 800px) {
                    width: 30vw;
                    max-width: 250px;
                }
            }
            span {
                text-transform: uppercase;
                font-size: 1.3rem;
                text-align: center, ;
                padding: 3vw;
                color: $base-white;
                font-family: "Commodore 64 Rounded";
            }
        }
    }
}

#texture {
    #texture-img {
        // background: url("./assets/images/old-paper.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: repeat-y;
        width: 100%;
        height: 300%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
}

#welcome {
    background: url("./assets/images/animated-bg.gif");
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -2;
    opacity: 0.9;
}

#welcome img {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 2600px;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    opacity: 1;
}

// #welcome .overlay {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: $base-blue;
//     mix-blend-mode: color;
// }
#welcome .upper-shadow {
    position: absolute;
    max-width: 2500px;
    top: 0;
    width: 100%;
    margin: 0 auto;
    height: 50px;
    // background: linear-gradient(to top, transparent, $base-light-green);
}

#news-banner {
    position: absolute;
    display: flex;
    bottom: 2vh;
    left: 0;
    width: 100%;
    height: 5vh;
    min-height: 60px;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    h3 {
        @media (max-width: 800px) {
            // flex-direction: column !important;
            display: none !important;
        }
        display: flex;
        align-items: center;
        vertical-align: middle;
        background-color: $base-glow-green;
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        color: $base-white;
        font-size: 2.5rem !important;
        font-family: "Commodore 64 Rounded";
        span {
            font-size: 2.5rem !important;
            vertical-align: middle;
            color: $base-glow-green;
            background-color: $base-white;
            padding: 30px 5px;
        }
    }
    #news-message-container {
        @keyframes slide-msg {
            0% {
                margin-left: 0;
            }
            100% {
                margin-left: -100%;
            }
        }
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        font-family: "Commodore 64 Rounded";
        color: $base-glow-green;
        overflow: hidden;
        div {
            position: absolute;
            height: 100%;
            min-width: 100%;
            &:after,
            p {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                font-size: 2rem;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
                white-space: nowrap;
                top: 0;
                animation: slide-msg 15s infinite linear;
            }
            span {
                margin-right: 4vw;
            }
            span:nth-child(2n+0) {
                color: $base-white;
            }
        }
    }
}

.content-container {
    width: 70%;
    border-radius: 3px;
    margin-top: 20px;
    height: 300px;
    /* background-color: rgba(130, 125, 143, 0.9); */
}

.footer {
    padding: 20px;
    display: flex;
}

#animation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    p {
        text-align: center;
        letter-spacing: 0.2rem;
        color: $base-glow-green;
        font-family: "Commodore 64 Rounded";
        font-size: 60px;
        text-shadow: 0 0 5px $base-blue;
        @media (max-width: 900px) {
            margin-top: 5vh;
            font-size: 1.8rem;
        }
    }
}

#about {
    position: relative;
    min-height: 80vh;
    width: 100%;
    max-width: 1000px;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // z-index: 8;
    padding: 100px 3vw;
    h6 {
        font-size: 5rem;
        margin: 0;
        margin-bottom: 5vh;
        @media (max-width: 800px) {
            font-size: 3rem;
        }
    }
    .faq-element {
        width: 100%;
        h4 {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            width: 100%;
            cursor: pointer;
            text-align: left;
            margin-bottom: 1vh;
        }
        .faq-block-drawer {
            transform: scaleY(0);
            height: 0;
            overflow: hidden;
            transition: 1s ease all;
            text-align: justify;
            @media (max-width: 1150px) {
                text-align: left;
            }
            &.active {
                height: auto;
                transform: scaleY(1);
            }
        }
        .faq-block {
            transition: all 2s linear;
            position: relative;
            padding: 3vh;
            border-right: 3px solid $base-glow-green;
            border-bottom: 2px solid $base-glow-green;
            background: rgba(0, 0, 0, 0.85);
            color: $base-glow-green;
            font-family: "Commodore 64 Rounded";
            text-transform: uppercase;
            font-size: 1.3rem;
            line-height: 1.5rem;
            &:after {
                content: "";
                background: $base-glow-green;
                position: absolute;
                top: 0;
                right: 0;
                height: 4px;
                width: 40%;
            }
            &:before {
                content: "";
                background: $base-glow-green;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 25%;
                width: 3px;
            }
            span {
                color: $base-light-green;
            }
            .rank-details-container {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 6vh;
                &>* {
                    margin-left: 2vw;
                    margin-right: 2vw;
                }
                img {
                    width: 180px;
                }
            }
        }
    }
}

#roadmap {
    font-family: "Commodore 64 Rounded";
    position: relative;
    min-height: 80vh;
    width: 100%;
    max-width: 1300px;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // z-index: 8;
    padding: 100px 0;
    p {
        text-transform: uppercase;
        font-size: 1rem;
    }
    h6 {
        font-size: 6rem;
        margin: 0;
        margin-bottom: 5vh;
        @media (max-width: 800px) {
            font-size: 3rem;
        }
    }
    .vertical-timeline {
        width: 100%;
    }
    .vertical-timeline-element-content {
        border: none !important;
        box-shadow: none !important;
        border-left: 3px solid $base-glow-green !important;
        border-bottom: 2px solid $base-glow-green !important;
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            left: 0;
            height: 4px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 25%;
            width: 3px;
        }
    }
}

// ---------------------------------------------------------------------------
.MuiTypography {
    color: #00cccc !important;
}

.styled-box {
    padding-top: 50px;
    // background-color: rgba(0, 0, 0, 0.7);
}

.menu-button {
    width: 30px !important;
    color: $base-glow-green !important;
    border: 2px solid $base-glow-green !important;
    padding: 0 !important;
}

.menu-bottom-button {
    padding: 1vh;
    border: 1px solid $base-glow-green;
    width: 70%;
    margin: 0 auto;
    margin-left: 15%;
    text-align: center;
    margin-bottom: 2vh;
    font-family: "Commodore 64 Rounded";
    transition: 0.5s all ease;
    &:hover {
        background-color: $base-glow-green;
        color: rgba(0, 0, 0, 0.7);
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            left: 0;
            height: 4px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 25%;
            width: 3px;
        }
    }
}

.list-item-text {
    font-family: "Commodore 64 Rounded" !important;
}

.web3modal-modal-hitbox {
    background-color: rgba(0, 0, 0, 0.78) !important;
}

.web3modal-modal-card {
    background-color: rgba(0, 0, 0, 0.98) !important;
    box-shadow: 0px 0px 40px 20px $base-glow-green, 0px 0px 60px 40px $base-light-green;
    border-left: 3px solid $base-glow-green;
    border-bottom: 2px solid $base-glow-green;
    border-radius: 0 !important;
    padding: 2vh !important;
    z-index: 999999999;
    &:after {
        content: "";
        background: $base-glow-green;
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 40%;
    }
    &:before {
        content: "";
        background: $base-glow-green;
        position: absolute;
        bottom: 0;
        right: 0;
        height: 25%;
        width: 3px;
    }
    .web3modal-provider-wrapper {
        border: none;
    }
    .web3modal-provider-name {
        font-family: "Commodore 64 Rounded";
        color: $base-light-green;
    }
    .web3modal-provider-description {
        color: $base-glow-green;
    }
    .web3modal-provider-container {
        position: relative;
        background-color: rgba(0, 0, 0, 0.7) !important;
        border-right: 1px solid $base-glow-green;
        border-bottom: 1px solid $base-glow-green;
        border-radius: 0 !important;
        padding: 2vh !important;
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 1px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 1px;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.2) !important;
        }
    }
}

#not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mega {
        font-size: 10vh !important;
        margin-top: 10vh;
        margin-bottom: 10vh;
    }
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: -1;
    }
    .glow {
        text-transform: uppercase;
        text-align: center;
    }
    a {
        margin-top: 5vh;
        position: relative;
        color: $base-glow-green;
        font-size: 2rem;
        font-family: "Commodore 64 Rounded";
        border-right: 3px solid $base-glow-green;
        border-bottom: 3px solid $base-glow-green;
        border-radius: 0 !important;
        padding: 2vh !important;
        text-transform: uppercase;
        &:after {
            content: "";
            background: $base-glow-green;
            position: absolute;
            top: 0;
            right: 0;
            height: 3px;
            width: 40%;
        }
        &:before {
            content: "";
            background: $base-glow-green;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 3px;
        }
        &:hover {
            color: $base-white;
            border-color: $base-white;
            // background-color: $base-glow-green;
            &:before {
                background-color: $base-white;
            }
            &:after {
                background-color: $base-white;
            }
        }
    }
}